export const BASE_URL = "https://backend.koch-ra.de"
export const WP_API_URL = BASE_URL + "/wp-json/wp/v2/";
const menuOrder = "orderby=menu_order&order=asc";
export const WP_URL_POSTS = WP_API_URL + "posts?_embed&per_page=100";
export const WP_URL_LAWYERS = WP_API_URL + "lawyers?" + menuOrder;
export const WP_URL_AREAS = WP_API_URL + "areas?" + menuOrder;
export const WP_URL_GLOSSARY = WP_API_URL + "glossary?per_page=100";
export const WP_API_PAGES = WP_API_URL + "pages/"
export const WP_URL_CONTACT         = WP_API_PAGES + "28";
export const WP_URL_PRIVACY         = WP_API_PAGES + "3";
export const WP_URL_PRIVACY_CLIENT  = WP_API_PAGES + "11";
export const WP_URL_IMPRESSUM       = WP_API_PAGES + "8";
export const WP_URL_COOKIES         = WP_API_PAGES + "50";
export const MAPS_API_KEY = "AIzaSyA-Tah5Yc4LjKupvL-jn2DONycg_GhQlE0";
export const WP_FORM_URL            = BASE_URL + "/wp-json/contact-form-7/v1/contact-forms/86/feedback"
import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import {decode} from 'html-entities';

export default function InfoCard(props) {
    const onClick = (e) => {
        props.onClick?.(props.target);
    }
    return (
        <Stack 
            spacing={props.spacing || 1}
            sx={{
                width: {
                    xs: '150px',
                    md: '200px',
                }
        }}>
            <Box sx={{
                height: '40px',
                marginLeft: {
                    xs: 'auto',
                    md: 0,
                },
                marginRight: 'auto',
                marginBottom: theme => ({
                    xs: 0,
                    md: theme.spacing(3)
                })
            }}>
                {props. icon && <Box
                    component="img"
                    sx={{
                        height: '100%',
                    }}
                    alt={props.buttonText}
                    src={props.icon}
                />}
            </Box>
            <Box>
                <Typography 
                    sx={{
                        color: (theme) => theme.palette.blue.main,
                        display: {
                            xs: 'none',
                            md: 'block'
                        }
                    }}
                    variant="body2"
                    align="left"
                    minHeight="120px"
                >
                    {props.body}
                </Typography>
            </Box>
            <Box sx={{
                marginLeft: 0,
                marginRight: 'auto',
                textAlign: {
                    xs: 'center',
                    md: 'left',
                }
            }}>
                <Button color="blue" sx={{color: 'white'}} variant="contained" onClick={onClick}>{decode(props.buttonText)}</Button>
            </Box>
        </Stack>
    )
}
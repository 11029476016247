import { Box } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';

const FooterLink = styled(Link)(({ theme }) => ({
    color: theme.palette.blue.main,
    fontFamily: theme.typography.primary.fontFamily,
    textTransform: 'uppercase',
    textDecoration: 'none',
    fontWeight: 800,
    marginRight: 0,
    [theme.breakpoints.up("xs")]: {
        fontSize: '.6rem',
        marginLeft: theme.spacing(0),
    },
    [theme.breakpoints.up("md")]: {
        fontSize: '.9rem',
        marginLeft: theme.spacing(6),
    },
    '&:hover': {
        textDecoration: 'underline',
    },
  }));

export default function Footer(props) {

    const handleClick = (link) => {
        props.onClickLink?.(link);
    }
    return (
        <Box sx={{
            height: '15vh',
            backgroundColor:(theme) => theme.palette.primary.grey,
            display:'flex',
            alignItems:'center',
            justifyContent: theme => ({
                xs: 'space-around',
                md: 'flex-start',
            }),
            width: theme => ({
                xs: '100%',
                md: `calc(100% - ${theme.spacing(3)})`,
            }),
            paddingLeft:(theme) => ({
                xs: 0,
                md: theme.spacing(3),
            })
        }}>
            <FooterLink to="" onClick={() => handleClick("impressum")}>Impressum</FooterLink>
            <FooterLink to="" onClick={() => handleClick("privacy")}>Datenschutz</FooterLink>
            <FooterLink to="" onClick={() => handleClick("notes")}>Hinweise für Mandanten</FooterLink>
        </Box>
    )
}
import { Link as MuiLink, Box, Grid, Stack, styled, Typography } from "@mui/material";
import React from "react";
import LabeledImgIcon from './LabeledImgIcon';
import MailIcon from '../res/icons/email.png';
import PhoneIcon from "../res/icons/telephone.png";
import PrinterIcon from "../res/icons/printer.png";
import ClockIcon from "../res/icons/clock.png";
import LocationIcon from "../res/icons/placeholder.png";
import { useCookies } from "react-cookie";
import WpContactContent from "./modules/WpContactContent";
import { Email } from "react-obfuscate-email";

const ContactLink = styled(MuiLink)(({theme}) => ({
    color: theme.palette.primary.light,
}))


export default function ContactPage(props) {
    const [cookies] = useCookies();

    const lineFeedToBreak = (text) => {
        return text == null ? "" : text.replaceAll('\n','<br />');
    }
    return (
            <Grid
                container
                rowSpacing={{
                    xs: 4,
                    md: 16,
                }}
                sx={{
                    width:'95%',
                    marginLeft: ({
                        xs: '0%',
                        md: '2%',
                    }),
                    marginTop:(theme) => ({
                        xs: theme.spacing(-3),
                        md: theme.spacing(-6),
                    }),
                }}
            >
                <Grid item xs={12} md={5} sx={{
                        textAlign: 'left',
                        '& p': {
                            marginBottom: '22px',
                        }
                        }}>
                    <WpContactContent content={props.content?.content?.rendered || ""} />
                </Grid>
                <Grid item xs={12} md={7}>
                    {cookies.cookiePref?.comfort === true && <Box
                        component="iframe"
                        title="location_maps"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2447.6182614001996!2d10.530102716069722!3d52.15945167974748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a55e8faf24137d%3A0xd14ff26ad721676d!2sBahnhof%201%2C%2038300%20Wolfenb%C3%BCttel!5e0!3m2!1sen!2sde!4v1658570920144!5m2!1sen!2sde"
                        width="600"
                        height="450"
                        allowFullScreen
                        loading="lazy"
                        sx={{
                            border:0,
                            minWidth:'500px',
                            width:'100%',
                            minHeight:'300px',
                            height: '170%',
                            marginTop: '-10%',
                            maxHeight:'800px',
                            display: {
                                xs: 'none',
                                md: 'block',
                            }
                        }}
                        referrerPolicy="no-referrer-when-downgrade">
                        
                    </Box>}
                </Grid>
                <Grid item xs={12}>
                    <Stack direction={{xs:'column',md:'row'}} spacing={{xs:1,sm:2,md:4}} justifyContent="space-between" alignContent="flex-start">
                        <LabeledImgIcon iconSrc={MailIcon}>
                            <Typography sx={{
                                color:'white',
                                textDecoration: 'none',
                                '&:hover': {
                                    textDecoration: 'underline',
                                }
                                }}
                                component={Email} email={props.content?.acf?.email || "test@test"}
                            >
                                {props.content?.acf?.email}
                            </Typography>
                        </LabeledImgIcon>
                        <Stack spacing={3}>
                            <LabeledImgIcon iconSrc={PhoneIcon}>
                                <ContactLink href={"tel:"+props.content?.acf?.phone} underline="hover">{props.content?.acf?.phone}</ContactLink>
                            </LabeledImgIcon>
                            <LabeledImgIcon iconSrc={PrinterIcon}>
                                <ContactLink href={"tel:"+props.content?.acf?.fax} underline="hover">{props.content?.acf?.fax}</ContactLink>
                            </LabeledImgIcon>
                        </Stack>
                        <LabeledImgIcon iconSrc={ClockIcon} textAlign="right">
                            <WpContactContent content={lineFeedToBreak(props.content?.acf?.hours)} />
                        </LabeledImgIcon>
                        <LabeledImgIcon iconSrc={LocationIcon}>
                            <WpContactContent content={lineFeedToBreak(props.content?.acf?.address)} />
                        </LabeledImgIcon>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Box
                        component="iframe"
                        title="location_maps"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2447.6182614001996!2d10.530102716069722!3d52.15945167974748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a55e8faf24137d%3A0xd14ff26ad721676d!2sBahnhof%201%2C%2038300%20Wolfenb%C3%BCttel!5e0!3m2!1sen!2sde!4v1658570920144!5m2!1sen!2sde"
                        width="600"
                        height="450"
                        allowFullScreen
                        loading="lazy"
                        sx={{
                            border:0,
                            height: theme => ({
                                xs: 'calc(100% - 48px)',
                                md: '170%',
                            }),
                            marginTop: theme => ({
                                xs: '10%',
                                md: '-10%',
                            }),
                            display: {
                                xs: 'block',
                                md: 'none',
                            },
                            marginBottom: theme => ({
                                xs: theme.spacing(24),
                            })
                        }}
                        referrerPolicy="no-referrer-when-downgrade">
                        
                    </Box>
                </Grid>
            </Grid>

    )
}

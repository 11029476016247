import { Box, Drawer, styled, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { ImportantButton, LoadButton } from "./buttons";
import * as Yup from 'yup';
import React from "react";
import axios from "axios";
import { WP_FORM_URL } from "./const/settings";

const FormTextField = styled(TextField)(({theme}) => ({
    color: theme.palette.primary.light,
    '& label': {
        color: theme.palette.blue.light,
        fontFamily: theme.typography.primary.fontFamily,
    },
    '& label.Mui-focused': {
      color: theme.palette.blue.light,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme => theme.palette.blue.light,
    },
    '& .MuiOutlinedInput-root': {
        color: theme.palette.primary.light,
        borderBottom: `2px ${theme.palette.blue.light} solid`,
        borderRadius: 0,
        '& fieldset': {
            border: 'none',
            borderBottomColor: theme => theme.palette.blue.light,
        },
        '&:hover fieldset': {
            border: 'none',
            borderBottomColor: theme => theme.palette.blue.light,
        },
        '&.Mui-focused fieldset': {
            border: 'none',
            borderBottomColor: theme => theme.palette.blue.light,
      },
    },
  }));

const formSchema = Yup.object().shape({
    name: Yup.string()
        .min(1, 'Dieser Name ist zu kurz. Bitte geben Sie Ihren vollen Namen ein.')
        .required('Bitte geben Sie Ihren vollen Namen ein.'),
    email: Yup.string().email('Ungültige E-Mail-Adresse.').required('Bitte geben Sie für die Beantwortung Ihrer Anfrage Ihre E-Mail-Adresse ein.'),
    message: Yup.string().min(1, 'Diese Anfrage ist zu kurz.').required('Schildern Sie uns bitte Ihr Anliegen.'),

})

export default function ContactForm(props) {
    const [submitting, setSubmitting] = React.useState(false);
    const [hasSubmitted, setHasSubmitted] = React.useState(false);
    const formik = useFormik({
        initialValues: {
            email: '',
            name: '',
            message: '',
            phone: '',

        },
        validationSchema: formSchema,
        onSubmit: values => {
            setSubmitting(true);
            const formData = new FormData();
            formData.append("your-name", values.name);
            formData.append("your-email", values.email);
            formData.append("your-phone", values.phone);
            formData.append("your-message", values.message);
            axios.post(WP_FORM_URL, formData,
            {
                headers: {
                    'content-type' : 'multipart/form-data',
                }
            }
            )
            .then(res => {
                console.log("Response: " + JSON.stringify(res.data, null, 2));
                setSubmitting(false);
                setHasSubmitted(true);

            })
            .catch(err => {
                console.log("Error submitting request!");
                setSubmitting(false);
            })
        },
    });

    return (
        <Box
            sx={{
                background: props.backgroundImage ? `url(${props.backgroundImage})` : 'aqua',
                backgroundPosition: 'right',
                backgroundSize: 'auto 100%',
                overflowX: 'hidden',
                width: theme => `calc(100%)`,
                height: theme => `calc(100%)`,
            }}
            >
            <Box
                sx={{
                    width: '200%',
                    display: 'flex',
                    transform: `translate(${hasSubmitted ? '-50%' : '0%'}, 0%)`,
                    transition: 'transform 500ms',
                    transitionTimingFunction: 'ease-in-out',
                }}
                >
                <Box
                    sx={{
                        padding: theme => theme.spacing(0),
                        paddingLeft: theme => ({
                            xs: theme.spacing(1),
                            md: theme.spacing(12),
                        }),
                        paddingTop: theme => ({
                            xs: theme.spacing(3),
                            md: theme.spacing(12),
                        }),
                        width: theme => ({
                            xs:  `calc(100% - ${theme.spacing(1)})`,
                            md:  `calc(100% - ${theme.spacing(12)})`,
                        }),
                    }}
                >
                    <form onSubmit={formik.handleSubmit}>
                        <Typography variant="h1" sx={{
                            color: theme => theme.palette.primary.light,
                            fontSize: '42px',
                            fontWeight: 300,
                            lineHeight: 1.2,
                            marginBottom: theme=> ({
                                xs: theme.spacing(2),
                                md: theme.spacing(9),
                            })
                            }}>
                                Kontaktieren<br /> Sie uns!
                        </Typography>
                        <FormTextField
                            margin="normal"
                            sx={{
                                marginBottom: theme => ({
                                    xs: theme.spacing(2),
                                    md: theme.spacing(5),
                                }),
                            }}
                            fullWidth
                            id="name"
                            label="Name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                            />
                        <FormTextField
                            margin="normal"
                            sx={{
                                marginBottom: theme => ({
                                    xs: theme.spacing(2),
                                    md: theme.spacing(5),
                                }),
                            }}
                            fullWidth
                            id="email"
                            label="E-mail"
                            type="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            />
                        <FormTextField
                            margin="normal"
                            sx={{
                                marginBottom: theme => ({
                                    xs: theme.spacing(2),
                                    md: theme.spacing(5),
                                }),
                            }}
                            fullWidth
                            id="phone"
                            label="Telefon (Optional)"
                            type="tel"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                            helperText={formik.touched.phone && formik.errors.phone}
                            />
                        <FormTextField
                            margin="normal"
                            sx={{
                                marginBottom: theme => ({
                                    xs: theme.spacing(2),
                                    md: theme.spacing(5),
                                }),
                            }}
                            fullWidth
                            id="message"
                            label="Nachricht"
                            type="text"
                            multiline
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            error={formik.touched.message && Boolean(formik.errors.message)}
                            helperText={formik.touched.message && formik.errors.message}
                        />
                        <Box sx={{
                            marginTop: theme => theme.spacing(5),
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <LoadButton disabled={submitting} loading={submitting} type="submit">Absenden</LoadButton>
                        </Box>
                    </form>
                </Box>
                <Box
                    sx={{
                        padding: theme => theme.spacing(0),
                        paddingLeft: theme => ({
                            xs: theme.spacing(3),
                            md: theme.spacing(12),
                        }),
                        paddingTop: theme => theme.spacing(12),
                        width: theme => ({
                            xs: `calc(100% - ${theme.spacing(3)})`,
                            md: `calc(100% - ${theme.spacing(12)})`,
                        }) 
                    }}
                >
                    <Typography variant="h1" sx={{color: theme => theme.palette.primary.light, fontSize: '48px', fontWeight: 300, lineHeight: 1.2, marginBottom: theme=>theme.spacing(9)}}>Vielen Dank<br />für Ihre Anfrage!</Typography>
                    <Typography sx={{color: theme => theme.palette.primary.light}}>
                        Wir werden Ihre Anfrage sobald wie möglich beantworten.
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}
import { Box, Fade, IconButton, Modal, Stack, Typography } from "@mui/material";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Container } from "@mui/system";

export default function LargeModal(props) {

    const handleClose = () => {
        props.handleClose?.();
    }
    return (
        <Modal
            open={props.open}
            onClose={handleClose}
            aria-labelledby={props.title}
        >
            <Fade in={props.open}>
                <Box sx={{
                    width: '80vw',
                    height: '80vh',
                    marginLeft: '10vw',
                    marginTop: '10vh',
                    backgroundColor: (theme) => props.backgroundColor || theme.palette.primary.light,
                    overflowY: 'scroll',
                }}>
                    <Stack>
                        <Box sx={{textAlign:'right', position:'sticky', top:0,}}>
                            <IconButton onClick={handleClose} size="36px" sx={{fontSize: '36px', fontWeight: 'light', color: (theme) => theme.palette.primary.main}} variant="text">
                                <CloseOutlinedIcon sx={{fontSize: '3rem'}}/>
                            </IconButton>
                        </Box>
                        <Container maxWidth={false} sx={{height:'100%', maxWidth: '1600px'}}>
                            <Typography sx={{fontWeight:'bold', marginBottom: (theme) => theme.spacing(6)}}>
                                {props.title}
                            </Typography>
                            <Box sx={{overflowY: 'auto'}}>
                                {props.children}
                            </Box>
                        </Container>
                    </Stack>
                </Box>

            </Fade>
        </Modal>
    )
}
import { Typography } from "@mui/material";
import { styled } from "@mui/system";

export const FormattedType = styled((props) => {
    return (
        <Typography {...props} />
    )
})(({theme, light, bold}) => ({
    color: light ? theme.palette.primary.light : theme.palette.primary.main,
    fontWeight: bold ? "bold" : 400,
}));
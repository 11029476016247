import { LoadingButton } from "@mui/lab";
import { Button, styled } from "@mui/material";
import { HashLink } from "react-router-hash-link";


export const ImportantButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.red.main,
    textTransform: 'none',
    fontWeight: 400,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderRadius: '10px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.red.main,
    },
  }));

  export const LoadButton = styled(LoadingButton)(({ theme }) => ({
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.red.main,
    textTransform: 'none',
    fontWeight: 400,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderRadius: '10px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.red.main,
    },
  }));

export const NavLink = styled(HashLink)(({ theme }) => ({
    color: theme.palette.primary.main,
    textTransform: 'none',
    textDecoration: 'none',
    fontWeight: 400,
    fontSize: '1rem',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    fontFamily: theme.typography.primary.fontFamily,
    '&:hover': {
        
    },
}));

export const GlosLink = styled(HashLink)(({ theme, light }) => ({
  color: light ? theme.palette.primary.light : theme.palette.primary.main,
  fontStyle: 'italic',
  textDecoration: 'underline',
  fontWeight: 400,
  fontSize: '1rem',
  fontFamily: theme.typography.primary.fontFamily,
  '&:hover': {
      
  },
}));

export const NavButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.main,
    textTransform: 'none',
    textDecoration: 'none',
    fontWeight: 400,
    fontSize: '1rem',
    fontFamily: theme.typography.primary.fontFamily,
    padding:0,
    textAlign: 'left',
    '&:hover': {

    },
  }));
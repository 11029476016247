import { Typography } from "@mui/material";
import parse, {domToReact} from "html-react-parser";
import { FormattedType } from "../util/type";

const options = {
    replace: ({name, attribs, children,startIndex}) => {
        if(name === "p") {
            return (<FormattedType light>{domToReact(children, options)}</FormattedType>)
        } else if(name === "h1") {
            return (<FormattedType light bold>{domToReact(children, options)}</FormattedType>)
        } else if(name === "h2") {
            return (<FormattedType light bold>{domToReact(children, options)}</FormattedType>)
        }
    }   
}

export default function WpContactContent(props) {
    return parse(props.content, options)
}
import {createTheme } from '@mui/material/styles';

const primaryTypo = [
    "PT Sans Caption",
    "sans-serif",
].join(',');


let theme = createTheme({
    palette: {
        primary: {
            main: '#000',
            light: '#fff',
        },
        secondary: {
            main: '#000',
            light: '#fff',
        },
        grey: {
            main: '#4c4c4c',
        },
        lightGrey: {
            main: '#f2f2f2',
        },
        red: {
            main: '#a30000',
        },
        blue: {
            main: '#183750',
            light: '#47748c',
        }
    },
})

const getHeadingAttributes = (theme) => {
    return {
        fontFamily: primaryTypo,
        color: theme.palette.grey.main,
        fontSize: '1.2rem',
        fontWeight: 800,
        letterSpacing: '0.05rem',
        lineHeight: 2,
    }
}

theme = createTheme(theme, {
    typography: {
        primary: {
            fontFamily: primaryTypo,
        },
        body: {
            fontFamily: primaryTypo,
        }
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: primaryTypo,
                    color: theme.palette.grey.main,
                },
                h1: {
                    ...getHeadingAttributes(theme),
                    textTransform: 'uppercase',
                    color: theme.palette.blue.light,
                },
                h2: {
                    ...getHeadingAttributes(theme),
                    marginBottom: theme.spacing(3),
                },
                h3: {
                    ...getHeadingAttributes(theme),
                    fontSize: '1.1rem',
                    marginBottom: theme.spacing(0),
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: primaryTypo
                }
            }
        }
    }
});

  export default theme;



import { Box, Container, Grid, IconButton, Stack, styled, TextField, Typography } from '@mui/material';
import React from 'react';
import PostCard from './modules/PostCard';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import WpApiPostContent from './modules/WpApiPostContent';
import { getPostDate } from './util/format';

const PageButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: '#d4d9de',
    borderRadius: '0px',
    padding: 0,
    '& .Mui-disabled': {
        backgroundColor: '#fff',
        
    }
}));

const Pagination = (props) => {
    const onChangePage = (page) => {
        props.onChange?.(page);
    }

    return(
        <Box sx={{display:'flex', marginBottom:(theme) => theme.spacing(6), marginRight: (theme) => theme.spacing(6)}}>
            <TextField
                id="page-select"
                value={props.page + 1}
                sx={{
                    padding:0,
                    marginTop: '1px',
                    '& .MuiTextField-root': {
                        padding: 4,
                    }
                }}
                inputProps = {{
                    sx: {
                        padding: (theme) => 0,
                        textAlign: 'center',
                        verticalAlign: 'unset',
                        width:"40px",
                    }
                }}
                variant="outlined"
                onChange={(e) => onChangePage(e.target.value -1)}
                // onKeyUp={(e) => {
                //     if(e.key === 'Enter') {
                //         onChangePage(e.target.value);
                //     }
                // }}
                // onBlur={(e) => {
                //     onChangePage(e.target.value);
                // }}
            />
            <Typography sx={{marginLeft: (theme) => theme.spacing(1), marginRight: (theme) => theme.spacing(3)}}>/ {props.pages}</Typography>
            <PageButton color="blue" disabled={props.page === 0} onClick={() => onChangePage(props.page-1)}>
                <NavigateBeforeIcon />
            </PageButton>
            <PageButton color="blue" disabled={props.page === props.pages-1} onClick={() => onChangePage(props.page + 1)}>
                <NavigateNextIcon />
            </PageButton>
        </Box>
    )
}

export default function NewsPage(props) {
    const [page, setPage] = React.useState(0);

    const getPostRelativeId = (postId) => {
        let r = 0;
        if(props.posts?.length) {
            for (let i = 0; i<props.posts.length; i++) {
                if(props.posts[i].id === postId) {
                    r = i;
                }
            }
        }
        return r;
    }

    const onChangePage = (newPage) => {
        setPage(newPage);
    }

    const onChangePost = (newPost) => {
        console.log("New post: " + newPost);
        props.onSelectPost?.(newPost);
    }

    const onSelectPost = (postId) => {

        props.onSelectPost?.(getPostRelativeId(postId));
    } 

    const findSelectedPost = () => {
        return props.posts?.find(it => it.id === props.selectedPost)
    }

    const getAllPosts = () => (
        <>
            <Grid
                sx={{
                    minHeight:'50vh',
                    marginTop:theme => ({
                        xs: theme.spacing(-3),
                        md: theme.spacing(-3),
                    }),
                    justifyContent: {
                        xs: 'flex-start',
                        md: 'space-around',
                    },
                    
                }}
                rowSpacing={6}
                container
                columns={17}
            >
                {props.posts?.slice(page*3, (page*3)+3).map((post, i) => (
                    <Grid
                        sx={{
                            maxWidth: '100%',
                            flexBasis: theme => ({
                                xs: `calc(100% - ${theme.spacing(6)})`,
                                md: 'auto',
                            })
                        }} 
                        key={i}
                        item
                        xs={12}
                        md={4}
                    >
                        <PostCard
                                title={post?.title?.rendered}
                                date={getPostDate(post?.date)}
                                body={post?.content?.rendered}
                                onSelect={() => onSelectPost(post?.id)}
                                />
                    </Grid>    
                ))}
            </Grid>
            <Box sx={{display:'flex', justifyContent:'flex-end'}}>
                <Pagination page={page} pages={Math.ceil(props.posts.length / 3)} onChange={onChangePage} />
            </Box>
        </>
    )

    const getSinglePost = () => (
        <>
        <Container
            maxWidth="false"
            sx={{
                paddingLeft: theme => ({
                    xs: theme.spacing(0),
                    md: theme.spacing(6),
                }),
            }}
        >
            <Stack sx={{
                height: {
                    xs:'auto',
                    md:'50vh',
                },
                textAlign: 'left',
                marginTop: (theme) => ({
                    xs: theme.spacing(3),
                    md: theme.spacing(9),
                }),
                width: ({
                    xs: '100%',
                    md: '90%',
                }),
                marginLeft: {
                    xs: '0%',
                    md: '5%',
                }
            }}>
                <Typography sx={{fontWeight: '800',marginBottom: (theme) => theme.spacing(3)}} gutterBottom>{props.posts[props.selectedPost]?.title?.rendered}</Typography>
                <Box sx={{
                    height: ({
                        xs: 'auto',
                        md: '500px',
                        xl: '800px',
                    }),
                    marginBottom: (theme) => theme.spacing(6),
                    overflowY: {
                        xs: 'unset',
                        md: 'scroll',
                    },
                    paddingRight: (theme) => theme.spacing(12),
                    '&::-webkit-scrollbar-track': {
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: (theme) => theme.palette.grey.main,
                        borderRadius: '25px',
                        border: 'thin solid',
                        borderColor: (theme) => theme.palette.primary.light,
                    },
                    '&::-webkit-scrollbar': {
                        background: (theme) => theme.palette.primary.light,
                        borderRadius: '25px',
                        width: '10px',
                        border: '1px solid',
                        borderColor: (theme) => "#ddd",
                        
                    },
                    
                }}>
                    <Typography>
                        <WpApiPostContent content={ props.posts[props.selectedPost]?.content?.rendered} />
                    </Typography>
                </Box>
            </Stack>
        </Container>
        <Box sx={{display:'flex', justifyContent:'flex-end'}}>
            <Pagination page={props.selectedPost} pages={props.posts.length} onChange={onChangePost} />
        </Box>
        </>
    )
    return (
        <Stack>
            {props.selectedPost !== -1 ? getSinglePost() : getAllPosts()}
        </Stack>
    )
}
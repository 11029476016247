import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, Modal, Stack, Typography } from "@mui/material";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Container } from "@mui/system";
import React, { useEffect } from "react";
import { ImportantButton } from "../buttons";
import WpApiPostContent from "./WpApiPostContent";
import axios from "axios";
import { WP_URL_COOKIES } from "../const/settings";

const COOKIE_BACKUP_TEXT = `<p>Wir verwenden Cookies und ähnliche Technologien auf unserer Website und verarbeiten personenbezogene Daten über Sie, wie Ihre IP-Adresse.
Wir teilen diese Daten auch mit Dritten. Die Datenverarbeitung kann mit Ihrer Einwilligung oder auf Basis eines berechtigten Interesses erfolgen,
dem Sie in den individuellen Datenschutzeinstellungen widersprechen können. Sie hast das Recht, nur in essenzielle Services einzuwilligen und
Ihre Einwilligung in der Datenschutzerklärung zu einem späteren Zeitpunkt zu ändern oder zu widerrufen.</p>`

export default function CookieBanner(props) {
    const [cookiesSelected, setCookiesSelected] = React.useState({comfort: true});
    const [cookieBannerContent, setCookieBannerContent] = React.useState(null);
    const [bannerReady, setBannerReady] = React.useState(false);
    const handleClose = () => {
        console.log("Cookies: " + JSON.stringify(cookiesSelected));
        props.onClose?.(cookiesSelected);
    }

    useEffect(() => {
        axios.get(WP_URL_COOKIES)
        .then(function(response) {
            console.log("Have response:" + response.data.content.rendered);
            setCookieBannerContent(response?.data);
            setBannerReady(true);
        })
        .catch(function (error) {
            console.log("Error fetching cookie banner content: " + error);
        })
    }, [setCookieBannerContent, setBannerReady])

    return (
        <Modal
            open={props.open}
            onClose={handleClose}
            aria-labelledby={props.title}
            sx={{
                overflowY: 'scroll',
            }}
        >
            <Box sx={{
                backgroundColor: (theme) => props.backgroundColor || theme.palette.primary.light,
                width: ({
                    xs: '90%',
                    md: '50%',
                    lg: '40%',
                }),
                marginLeft: 'auto',
                marginRight: 'auto',
                
                minHeight:'300px',
            }}>
                <Stack>
                    <Box sx={{textAlign:'right'}}>
                        <IconButton onClick={handleClose} size="36px" sx={{fontSize: '36px', fontWeight: 'light', color: (theme) => theme.palette.primary.main}} variant="text">
                            <CloseOutlinedIcon sx={{fontSize: '3rem'}}/>
                        </IconButton>
                    </Box>
                    <Container sx={{height:'100%'}}>
                        <Typography sx={{fontWeight:'bold', marginBottom: theme => theme.spacing(3)}}>
                            {cookieBannerContent?.title?.rendered || "Privatsphäre-Einstellungen"}
                            
                        </Typography>
                        <Box sx={{
                                    marginBottom: theme => theme.spacing(3),
                                }}>
                            <WpApiPostContent content={cookieBannerContent?.content?.rendered || COOKIE_BACKUP_TEXT} />
                        </Box>
                        <FormControl sx={{gap:theme => theme.spacing(1), width: '100%', marginBottom: theme => theme.spacing(1)}}>
                            <FormLabel>Cookies Auswählen</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked disabled name="cookies-essential" />
                                    }
                                    label="Notwendig"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={cookiesSelected.comfort} onChange={(e) => setCookiesSelected({...cookiesSelected, comfort: e.target.checked})} name="cookies-comfort" />
                                    }
                                    label="Funktional"
                                />
                            </FormGroup>
                            <Box sx={{width:'100%', display:'flex', justifyContent:'flex-end', paddingBottom:theme=>theme.spacing(3)}}>
                                <ImportantButton color="red" variant="contained" onClick={handleClose}>Speichern</ImportantButton>
                            </Box>
                        </FormControl>
                            
                    </Container>
                </Stack>
            </Box>
        </Modal>
    )
}
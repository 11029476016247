import { Box, Container } from "@mui/material";
import WpApiPostContent from "./modules/WpApiPostContent";

export default function DetailPage(props) {
    const handleGlos = (id) => {
        props.handleGlos?.(id);
    }
    return (
        <Container
            maxWidth="false"
            sx={{
                maxHeight: theme => ({
                    xs: 'unset',
                    md: '75vh',
                }),
                height: ({
                    xs: 'auto',
                    md: '80%',
                }),
                overflowY: theme => ({
                    xs: 'unset',
                    md: 'auto',
                }),
                '&::-webkit-scrollbar-track': {
                },
                '&::-webkit-scrollbar-thumb': {
                    background: (theme) => theme.palette.blue.light,
                    borderRadius: '25px',
                    border: 'thin solid',
                    borderColor: (theme) => theme.palette.primary.light,
                },
                '&::-webkit-scrollbar': {
                    background: (theme) => theme.palette.primary.light,
                    borderRadius: '25px',
                    width: '10px',
                    border: '1px solid',
                    borderColor: (theme) => "#ddd",
                    
                },
                textAlign: 'left',
                maxWidth: ({
                    xs: '100%',
                    md: '100%',
                }),
                paddingLeft: theme => ({
                    xs: theme.spacing(0),
                    md: theme.spacing(6),
                }),
                color: (theme) => theme.palette.primary.light,
                marginTop: theme => ({
                    xs: theme.spacing(3),
                    md: theme.spacing(6),
                }),
                marginBottom: (theme) => theme.spacing(12),
            }}
        >
            <Box 
                sx={{
                    height: ({ 
                        xs: 'auto',
                        // md: '100%',
                    }),
                    '& a': {
                        color: (theme) => theme.palette.primary.light,
                        '&:visited': {
                            color: (theme) => theme.palette.primary.light,
                        },
                        '&:hover': {
                            textDecoration: 'none',
                        }
                    },
                }}
            >
                <WpApiPostContent light content={props.offers?.[props.detailSelected]?.content} glosHandler={handleGlos} />
            </Box>
        </Container>
    )
}
import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

export default function LabeledImgIcon(props) {
    return(
        <Stack direction="row" justifyContent="flex-start" alignItems={props.centered ? "center" : "flex-start"} spacing={2}>
            <Box component="img" src={props.iconSrc} sx={{height:'35px'}}></Box>
            <Typography sx={{color: (theme) => props.color || theme.palette.primary.light, textAlign:props.textAlign || 'left'}}>
                {props.children}
            </Typography>
        </Stack>
    )
}
import { Box, Container, Drawer, Fade, Stack, Typography } from '@mui/material';
import React, {useEffect } from 'react';
import FrontPage from './FrontPage';
import Footer from './nav/Footer';
import MainMenu from './nav/MainMenu';
import NewsPage from './NewsPage';
import Section from './Section';
// import offers from "../mockup/offers.json";
import ContactPage from './ContactPage';
import backgroundDetails from '../res/images/background_details.jpg';
import backgroundForm from '../res/images/background_form.jpg';
import backgroundContact from '../res/images/background_contact.jpg';
import DetailPage from './DetailPage';
import LargeModal from './LargeModal';
// import glossary from '../mockup/glossary.json';
import * as Scroll from 'react-scroll';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useCookies } from 'react-cookie';
import { ImportantButton } from './buttons';
import { useSearchParams } from 'react-router-dom';
import { WP_API_URL, WP_URL_AREAS, WP_URL_CONTACT, WP_URL_GLOSSARY, WP_URL_IMPRESSUM, WP_URL_LAWYERS, WP_URL_POSTS, WP_URL_PRIVACY, WP_URL_PRIVACY_CLIENT } from './const/settings';
import axios from 'axios';

// import posts from "../mockup/posts.json";
// import lawyers from "../mockup/lawyers.json";
import WpApiPostContent from './modules/WpApiPostContent';
import { getPostDate } from './util/format';
import ContactForm from './ContactForm';

export default function MainPage(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const scroll = Scroll.scroller;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const [cookies, setCookie, removeCookie] = useCookies();
    const [detailsExpanded, setDetailsExpanded] = React.useState(false);
    const [detailSelected, setDetailSelected] = React.useState(0);
    const [postSelected, setPostSelected]   = React.useState(-1);
    const [lawyer, setLawyer] = React.useState(null);
    const [glossaryOpen, setGlossaryOpen] = React.useState(false);
    const [selectedGlossary, setSelectedGlossary] = React.useState(0);
    const [pageReady, setPageReady] = React.useState(false);

    //Modals
    const [impressumOpen, setImpressumOpen] = React.useState(false);
    const [privacyOpen, setPrivacyOpen] = React.useState(false);
    const [notesOpen, setNotesOpen] = React.useState(false);
    const [formOpen, setFormOpen] = React.useState(false);

    const [posts, setPosts] = React.useState([]);
    const [contactContent, setContactContent] = React.useState({});
    const [privacyContent, setPrivacyContent] = React.useState({});
    const [privacyClientContent, setPrivacyClientContent] = React.useState({});
    const [impressumContent, setImpressumContent] = React.useState({});
    const [lawyers, setLawyers] = React.useState({});
    const [offers, setOffers] = React.useState([]);
    const [glossary, setGlossary] = React.useState([]);



    useEffect(() => {
        Promise.all([
            axios.get(WP_URL_POSTS),
            axios.get(WP_URL_CONTACT),
            axios.get(WP_URL_PRIVACY),
            axios.get(WP_URL_PRIVACY_CLIENT),
            axios.get(WP_URL_IMPRESSUM),
            axios.get(WP_URL_LAWYERS),
            axios.get(WP_URL_AREAS),
            axios.get(WP_URL_GLOSSARY),
        ]).then((
            [
                postResponse,
                contactResponse,
                privacyResponse,
                privacyClientResponse,
                impressumResponse,
                lawyersResponse,
                areasResponse,
                glossaryResponse,
            ]) => {
            setPosts(postResponse.data);
            setContactContent(contactResponse.data);
            setPrivacyContent(privacyResponse.data);
            setPrivacyClientContent(privacyClientResponse.data);
            setImpressumContent(impressumResponse.data);
            setLawyers(lawyersResponse.data);
            setOffers(areasResponse.data);
            setGlossary(glossaryResponse.data);
            setPageReady(true);
        }).catch(function(err) {
            console.log("Error loading page");
            setPageReady(true);
        });

        // console.log("G: " + searchParams.get("g"));
        // const g = searchParams.get("g");
        // if(g !== null && g !== "") {
        //     setSelectedGlossary(parseInt(g));
        //     const s = glossary?.find(item => item.id === selectedGlossary)
        //     console.log("S: " + s);
        //     if(s) {
        //         setGlossaryOpen(true);
        //     }
        // }
        
    }, [
        searchParams,
        setPosts,
        setContactContent,
        setPrivacyContent,
        setPrivacyClientContent,
        setImpressumContent,
        setLawyers,
        setOffers,
        setGlossary,
        setSelectedGlossary,
        selectedGlossary,
        setGlossaryOpen,
    ]);

    const parseWpContentForGlossary = (posts) => {
        let scanned = [];
        for (let p of posts ) {
            let s = {};
            s.title     = p.title.rendered;
            s.summary   = p.acf.summary;
            s.icon      = p.acf.icon;
            s.content   = p.content.rendered;
            s.id        = p.id;
            for (let glos of glossary) {
                let regEx = new RegExp(glos.title.rendered, "ig");
                s.content = s.content.replace(regEx, "<glos href='"+glos.id+"'>"+glos.title.rendered+"</glos>");
            }
            scanned.push(s);
        }
        return scanned;
        
    } 

    const getOffers = () => {
        return parseWpContentForGlossary(offers)
    }


    const onCloseDetails = (e) => {
        console.log("on close");
        setDetailsExpanded(false);
    }

    const onCloseSinglePost = (e) => {
        setPostSelected(-1);
    }

    const onSelectDetail = (detail) => {
        console.log("Selected detail");
        matches && scroll.scrollTo("details",{
            smooth: true,
            offset: 112,
        });
        setDetailsExpanded(true);
        setDetailSelected(detail);
    }

    const onSelectPost = (post) => {
        scroll.scrollTo("news",{
            smooth: false,
            offset: 112,
        });
        console.log("Post selected:" + post);
        
        setPostSelected(post);
    }

    const onClickFooterLink = (link) => {
        switch(link) {
            case "impressum":
                setImpressumOpen(true);
                break;
            case "privacy":
                setPrivacyOpen(true);
                break;
            case "notes":
                setNotesOpen(true);
                break;
            default:
                break;
        }
    }

    const handleGlossarySelect = (id) => {
        setSelectedGlossary(id);
        setGlossaryOpen(true);
    }

    const getSelectedGlossary = () => {
        console.log("selected: " + selectedGlossary);
        const parsed = parseWpContentForGlossary(glossary);
        const g = parsed?.find(item => item.id === selectedGlossary)
        return g;
    }

    return (
        <Fade in={pageReady}>
            <Container
                disableGutters
                maxWidth="false"
                sx={{
                    padding:0,
                    maxWidth: '1920px',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px',
                }}
            >
                <MainMenu
                    onSelectLawyer={(id) => setLawyer(lawyers[id])}
                />
                <Drawer
                    anchor="right"
                    open={glossaryOpen}
                    variant="temporary"
                    onClose={() => setGlossaryOpen(false)}
                    className="glossaryDrawer"
                    PaperProps={{
                        sx: {
                            width: theme => ({
                                xs: `calc(100%)`, 
                                md: `calc(50% - ${theme.spacing(6)})`,
                            }),
                            padding: theme => theme.spacing(3),
                        },
                    }}
                >
                    <Typography variant="h2" sx={{textTransform:'uppercase'}}>{getSelectedGlossary()?.title}</Typography>
                    <Box sx={{
                        maxWidth: {
                            xs: '100%',
                            md: '90%',
                        }
                    }}>
                        <WpApiPostContent light={false} content={getSelectedGlossary()?.content} handleGlos={handleGlossarySelect} />
                    </Box>
                </Drawer>
                <Drawer
                    anchor="right"
                    open={formOpen}
                    variant="temporary"
                    onClose={() => setFormOpen(false)}
                    PaperProps={{
                        sx: {
                            width: theme => ({
                                xs: `calc(100%)`, 
                                md: `calc(50% - ${theme.spacing(6)})`,
                            }),
                        },
                    }}
                >
                    <ContactForm backgroundImage={backgroundForm} />
                </Drawer>
                <Box>
                    <FrontPage
                        offers={getOffers()}
                        id="home"
                        onSelectDetail={onSelectDetail}
                        onSelectLawyer={setLawyer}
                        lawyers={lawyers}
                        lawyer={lawyer}
                        onClickMessage={() => setFormOpen(true)}
                    />
                    <Section
                        titleColor={theme.palette.primary.light}
                        id="details"
                        onClose={onCloseDetails}
                        closeable
                        expanded={detailsExpanded}
                        title={offers[detailSelected]?.title.rendered}
                        backgroundImage={backgroundDetails}
                        zIndex={10}
                    >
                        <DetailPage offers={getOffers()} detailSelected={detailSelected} handleGlos={handleGlossarySelect}  />
                    </Section>
                    <Section
                        titleColor={postSelected !== -1 ? theme.palette.blue.light : theme.palette.grey.main}
                        expanded
                        closeable={postSelected !== -1}
                        onClose={onCloseSinglePost}
                        id="news"
                        title={postSelected !== -1 ? getPostDate(posts[postSelected]?.date) : "Aktuelles"}
                        backgroundColor={theme.palette.lightGrey.main}>
                        <NewsPage posts={posts} selectedPost={postSelected} onSelectPost={onSelectPost} />
                    </Section>
                    <Section
                        titleColor={theme.palette.primary.light}
                        expanded
                        id="contact"
                        title="Kontakt"
                        backgroundColor={theme.palette.blue.main}
                        backgroundImage={backgroundContact}
                    >
                        <ContactPage content={contactContent} />
                    </Section>
                    <Footer
                        onClickLink={onClickFooterLink}
                    />
                    <LargeModal
                        id="impressum"
                        open={impressumOpen}
                        handleClose={() => setImpressumOpen(false)}
                        title="Impressum"
                    >
                        <WpApiPostContent content={impressumContent?.content?.rendered} />
                    </LargeModal>
                    <LargeModal
                        id="privacy"
                        open={privacyOpen}
                        handleClose={() => setPrivacyOpen(false)}
                        title={privacyContent?.title?.rendered}
                    >
                        <Stack>
                            <Box><WpApiPostContent content={privacyContent?.content?.rendered} /></Box>
                            <Box sx={{width:'100%', display:'flex', justifyContent:'flex-end', paddingBottom:theme=>theme.spacing(3)}}>
                                <ImportantButton color="red" variant="contained" onClick={() => removeCookie('cookiePref')}>Cookie-Einstellungen</ImportantButton>
                            </Box>
                        </Stack>
                    </LargeModal>
                    <LargeModal
                        id="notes"
                        open={notesOpen}
                        handleClose={() => setNotesOpen(false)}
                        title={privacyClientContent?.title?.rendered}
                    >
                        <WpApiPostContent content={privacyClientContent?.content?.rendered} />
                    </LargeModal>
                </Box>
            </Container>
        </Fade>
    )
}
import { Box, Button, Card, CardActions, CardContent, styled, Typography } from '@mui/material';
import React from 'react';
import CalendarIcon from '../../res/icons/calendar.png';
import WpApiPostContent from './WpApiPostContent';

const GutterType = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(4)
}));

export default function PostCard(props) {

    const onClickRead = () => {
        props.onSelect?.();
    }
    return (
        <Card
            sx={{
                backgroundColor: 'none',    
                background: 'none', 
                border: 'none', 
                borderRadius: 0,    
                boxShadow: 'unset',
        }}>
            <CardContent
                sx={{
                    textAlign: 'left',
                    '&.MuiCardContent-root': {
                        padding: theme => ({
                            xs: theme.spacing(0),
                            md: theme.spacing(2),
                        })
    
                    } 
                }}
            >
                <GutterType sx={{fontSize: 14, verticalAlign: 'middle', fontWeight: 800, color: (theme) => theme.palette.blue.light}} gutterBottom>
                    <Box
                        component="img"
                        src={CalendarIcon}
                        sx={{
                            height:14,
                            marginRight: (theme) => theme.spacing(1)
                        }}
                        />
                    {props.date}
                </GutterType>
                <GutterType sx={{fontSize: 16, fontWeight: 800}} gutterBottom><WpApiPostContent content={props.title} /></GutterType>
                <Typography sx={{fontSize: 16}}><WpApiPostContent content={props.body?.substring(0,300)} />...</Typography>
            </CardContent>
            <CardActions sx={{
                paddingLeft: theme => ({
                    xs: theme.spacing(0),
                    md: theme.spacing(1),
                })
            }}>
                <Button
                    sx={{
                        textTransform: 'unset',
                        fontWeight: 'bold',
                        paddingLeft: theme => ({
                            xs :theme.spacing(0),
                            md :theme.spacing(1),
                        })
                    }}
                    color="red"
                    size="medium"
                    onClick={onClickRead}
                >Weiterlesen</Button>
            </CardActions>
        </Card>
    )
}
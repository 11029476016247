import { AppBar,IconButton, Menu, MenuItem, styled, Toolbar } from '@mui/material';
import React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { ImportantButton, NavButton, NavLink } from '../buttons';

const NavMenu = styled(Menu)(({theme}) => ({
    '& .MuiPaper-root': {
        background: theme.palette.lightGrey.main,
        textAlign: 'left',
    },
    '& .MuiMenuItem-root': {
        textAlign: 'left',
        '&:active': {
            backgroundColor: theme.palette.lightGrey.main,
        },
        '&:visited': {
            backgroundColor: theme.palette.lightGrey.main,
        },
        '&:hover': {
            backgroundColor: theme.palette.lightGrey.main,
        },
      },
}));

export default function MainMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [navOpen, setNavOpen] = React.useState(false);
    function handleClick(event) {
        event.preventDefault();
        if (anchorEl !== event.currentTarget) {
          setAnchorEl(event.currentTarget);
        }
      }
    const handleClose = () => {
      setAnchorEl(null);
    };

    const onClickLawyer = (lawyer) => {
        props.onSelectLawyer?.(lawyer);
    }

    return (
        <AppBar color="lightGrey"
                position="sticky"
                sx={{
                    boxShadow: 'none',
                    minHeight: (theme) => theme.spacing(10),
                    maxWidth:'100%',
                    paddingTop: theme => ({
                       xs: theme.spacing(0),
                       md: 0, 
                    }),
                    flexDirection: {
                        xs: 'row',
                    },
                    justifyContent: {
                        xs: 'space-between',
                        md: 'center',
                    },
                    alignItems: {
                        xs: 'flex-start',
                    }
                }}
            >
                <Toolbar
                    sx={[{
                        justifyContent: 'flex-end',
                        alignItems: {
                            xs: 'flex-start',
                            md: 'center',
                        },
                        gap: (theme) => ({
                            xs: theme.spacing(2),
                            md: 0,
                        }),
                        flexDirection: {
                            xs: 'column',
                            md: 'row',
                        },
                        width: theme => ({
                            xs: '50%',
                            md: '100%',
                        }),
                        marginTop: theme => ({
                            xs: '-250px',
                            md: 0,
                        }),
                        marginBottom: theme => ({
                            xs: theme.spacing(3),
                            md: 0,
                        }),
                        transition: (theme) => theme.transitions.create('margin-top', {
                            duration: theme.transitions.duration.short,
                          }),
                    },
                    navOpen && {
                        transform: 'translate(0, 0%)',
                        marginTop: theme => ({
                            xs: theme.spacing(2),
                            md: 0,
                        })
                    }
                    ]}
                >

                    <NavLink smooth to="#" variant="text">Start         </NavLink>
                    <NavLink smooth to="#lawyers">Schwerpunkte  </NavLink>
                    <NavLink to="#lawyers" variant="text"
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                // onMouseOver={handleClick}
                    >
                        <NavButton endIcon={<ArrowDropDownIcon />}>Anwälte</NavButton>
                    </NavLink>
                    <NavMenu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        elevation={0}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            // onMouseLeave: handleClose,
                        }}
                    >
                        
                        <MenuItem onClick={handleClose}>
                            <NavLink smooth to="#lawyers" onClick={() => onClickLawyer(0)}>Nikolas Koch</NavLink>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <NavLink smooth to="#lawyers" onClick={() => onClickLawyer(1)}>Justine Chwieduk</NavLink>
                        </MenuItem>
                    </NavMenu>
                    <NavLink to="#news" smooth>Aktuelles</NavLink>
                    <NavLink to="#contact" smooth>
                        <ImportantButton color="red" variant="contained">Kontakt</ImportantButton>
                    </NavLink>
                </Toolbar>
            <IconButton
                onClick={() => setNavOpen(false)}
                size="36px"
                sx={{
                    display: {
                        xs: navOpen ? 'inline-flex' : 'none',
                        md: 'none',
                    },
                    fontSize: '36px',
                    fontWeight: 'light',
                    color: props.titleColor
                }}
                variant="text"
            >
                <CloseOutlinedIcon sx={{fontSize: '3rem'}}/>
            </IconButton>
            <IconButton
                onClick={() => setNavOpen(true)}
                size="36px"
                sx={{
                    display: {
                        xs: navOpen ? 'none' : 'inline-flex',
                        md: 'none',
                    },
                    fontSize: '36px',
                    fontWeight: 'light',
                    color: props.titleColor
                }}
                variant="text"
            >
                <MenuOutlinedIcon sx={{fontSize: '3rem'}}/>
            </IconButton>
        </AppBar>
    )
}
import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import parse, {domToReact} from "html-react-parser";
import { GlosLink } from "../buttons";


export default function WpApiPostContent(props) {
    // console.log("parse: " + props.content);
    const options = {
        replace: ({name, attribs, children,startIndex}) => {
            if(name === "p") {
                return (
                    <Typography sx={{
                        marginBottom: (theme) => theme.spacing(1),
                        color: theme => props.light ? theme.palette.primary.light : theme.palette.grey.main,
                    }}>{domToReact(children, options)}</Typography>
                )
            } else if(name === "h1") {
                return (<Typography variant="h1" sx={{color: theme => props.light ? theme.palette.primary.light : theme.palette.grey.main,}}>{domToReact(children, options)}</Typography>)
            } else if(name === "h2") {
                return (<Typography variant="h2" sx={{color: theme => props.light ? theme.palette.primary.light : theme.palette.grey.main,}}>{domToReact(children, options)}</Typography>)
            } else if(name === "h3") {
                return (<Typography variant="h3" sx={{color: theme => props.light ? theme.palette.primary.light : theme.palette.grey.main,}}>{domToReact(children, options)}</Typography>)
            } else if(name === "ul") {
                return (<List sx={{
                    maxWidth: '1200px',
                    listStyle: 'square',
                    listStyleType: 'disc',
                    paddingTop: 0,
                    paddingLeft: theme => theme.spacing(3),
                    color: theme => props.light ? theme.palette.primary.light : theme.palette.grey.main,
                }}>{domToReact(children,options)}</List>);
            } else if(name === "li") {
                return (<ListItem sx={{
                    display:'list-item',
                    fontFamily: theme => theme.typography.primary.fontFamily,
                    color: theme => props.light ? theme.palette.primary.light : theme.palette.grey.main,
                }}>{domToReact(children,options)}</ListItem>);
            } else if(name === "table") {
                return (<Box sx={{
                    fontFamily: theme => theme.typography.primary.fontFamily,
                    color: theme => props.light ? theme.palette.primary.light : theme.palette.grey.main,
                    marginBottom: (theme) => theme.spacing(4),
                }} component="table">{domToReact(children,options)}</Box>)
            } else if(name === "tr") {
                return (<Box sx={{
                    '&:first-child': {
                        fontWeight: 800,
                    },
    
                }} component="tr">
                    {domToReact(children,options)}
                </Box>)
            } else if(name === "td") {
                return (<Box sx={{
                    '&:first-child': {
                        fontWeight: 800,
                    },
                    color: theme => props.light ? theme.palette.primary.light : theme.palette.grey.main,
                    paddingRight: theme => theme.spacing(6),
                }} component="td">
                    {domToReact(children,options)}
                </Box>)
            } else if(name === "figure") {
                return (<Box sx={{
                    marginInlineStart: 0,
                }} component="figure">
                    {domToReact(children,options)}
                </Box>)
            } else if(name === "glos") {
                const handleClick = () => {
                    props.glosHandler?.(parseInt(attribs.href));
                }
                return (<GlosLink light={props.light} onClick={handleClick} to={"?g=" + attribs.href}>
                    {domToReact(children,options)}
                </GlosLink>)
            }
        }   
    }
    return props.content && parse(props.content, options);
}
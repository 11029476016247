import { Box, Button, Container, Fade, Grid, IconButton, Slide, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import Logo from '../res/images/logo_main.png';
import LogoMobile from '../res/images/logo_mobile.png';
import Background from '../res/images/bg_home.jpg';
import Nikolas from "../res/images/Pic_Niko.jpg";
import Justine from "../res/images/Pic_Justine.jpg";
import InfoCard from './InfoCard';
import CloseArrow from "../res/icons/next_lg.png"
import theme from '../theme';
import { useParallax } from 'react-scroll-parallax';
import MailIcon from '../res/icons/email.png';
import PhoneIcon from "../res/icons/telephone.png";

const LawyerPortrait = styled((props) => {
    const { selected, img, gap, ...other } = props;
    return <Button {...other} />;
  })(({ theme, selected, img, gap }) => ({
    [theme.breakpoints.up('xs')]: {
        width: '130px',
        height: '115px',
        marginLeft: 0,
        marginTop: '-60px',
    },
    [theme.breakpoints.up('md')]: {
        width: '200px',
        height: '180px',
        marginLeft: gap,
        marginTop: '-110px',
    },
    [theme.breakpoints.up('lg')]: {
        width: '250px',
        height: '220px',
        marginLeft: gap,
        marginTop: '-110px',
    },
    position:'relative',
    zIndex:12,
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px',
    backgroundImage:`url(${img})`,
    
    backgroundPosition:'center',
    filter: selected ? 'grayscale(0%)' : 'grayscale(100%)',
    borderRadius: 0,
    backgroundSize: selected ? '150%' : '100%',
    backgroundPositionY: '20%',
    // transitionDuration: '1s',
    '&:hover': {
        filter:'grayscale(0%)',
        backgroundSize: '150%',
    },
    '&:active': {
        filter:'grayscale(0%)',
        backgroundSize: '150%',
    },
    transition: theme.transitions.create(['filter', 'background-size'], {
      duration: theme.transitions.duration.standard,
    }),
  }));


export default function FrontPage(props) {

    const onClickDetail = (detail) => {
        props.onSelectDetail?.(detail);
    }

    const setLawyer = (lawyer) => {
        props.onSelectLawyer?.(lawyer);
    }
    const upper = useParallax(
        {
            translateY: [-20,20]
        }
    )

    const onClickMessage = () => {
        props.onClickMessage?.();
    }
        
    const getLawyerBio = () => {
        return (
                <Slide direction="left" in={props.lawyer !== null && props.lawyer !== undefined} mountOnEnter unmountOnExit>
                <Box 
                    id="lawyerBio"
                    sx={{
                    width: ({
                        xs:'100%',
                        md: '66.5%',
                        lg: '50%',
                    }),
                    height: '100%',
                    left: 'auto',
                    right: 0,
                    position:'absolute',
                    zIndex:8,
                }}>
                    <Container sx={{
                        backgroundColor:(theme) => theme.palette.lightGrey.main,
                        height:'100%',
                        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px',
                    }}>
                        <Stack 
                        justifyContent="space-between"                                
                        sx={{

                            height:theme => ({
                                xs: `calc(100% - ${theme.spacing(13)})`,
                                md: `calc(100% - ${theme.spacing(25)})`,
                            }),
                            paddingTop: theme => ({
                                xs:theme.spacing(12),
                                md:theme.spacing(24),
                            }),
                            paddingLeft: theme => ({
                                xs: theme.spacing(2),
                                md: theme.spacing(6),
                            }),
                            width: ({
                                xs: '90%',
                                md: '80%',
                            }),
                            textAlign:'left',
                        }}>
                            <Box sx={{
                                overflowY: 'auto',
                                paddingRight: theme => theme.spacing(1),
                            }}>
                                <Typography sx={{fontSize:14, height:'90%'}}><span dangerouslySetInnerHTML={{__html: props.lawyer?.content?.rendered}}></span></Typography>
                            </Box>
                            <Button sx={{ width:'30px', minWidth:'unset', padding:0, marginBottom: theme => theme.spacing(3), marginTop: theme => theme.spacing(3)}} onClick={() => setLawyer(null)}>
                                <Box component="img" src={CloseArrow} sx={{ height:'30px', justifyContent:'flex-end', textAlign:'left', fontSize:48, marginLeft:0}}/>
                            </Button>
                        </Stack>
                    </Container>
                </Box>
            </Slide>
        )
    }

    return (
        <Box>
            <Grid id="home" rowSpacing={0} container sx={{
                    width: '100%',
                    backgroundColor: 'none',
                    alignContent: 'flex-start',
                    position:'relative',
                    top:0,
                    left:0,
                }}>
                    <Fade in={true}>
                        <Box    
                                sx={{
                                position:'absolute',
                                backgroundColor: (theme) => theme.palette.lightGrey.main,
                                backgroundImage: `url(${Background})`,
                                height:'100%',
                                width: '100%',
                                backgroundSize: ({
                                    xs: '300% auto',
                                    md: '100% auto',
                                    xl: '140% auto',
                                }),
                                backgroundPositionY: {
                                    xs: '-40%',
                                    sm: '-40%',
                                    md: '20%',
                                    lg: '-40%',
                                    xl: 'bottom',
                                },
                                backgroundPositionX: '30%',

                                backgroundRepeat: 'no-repeat',
                                zIndex: -20,
                            }}
                                ref={upper.ref}

                        >
                        
                        </Box>
                    </Fade>
                    <Grid item container rowSpacing={3} xs={12} sx={{
                        minHeight: theme => ({
                            xs: '450px',
                            md: '600px',
                            lg: '700px',
                        }),
                        backgroundSize: '100% auto',
                        backgroundPositionY: '30%',
                        backgroundRepeat: 'no-repeat',
                        alignContent:'flex-start',
                    }}>
                        <Grid item container xs={12} sx={{
                            minHeight: '100px',
                            alignItems: 'flex-start',
                            paddingLeft: (theme) => ({
                                xs: theme.spacing(3),
                                md: theme.spacing(9),
                            }),
                        }}>
                            <Box component="img" src={Logo} sx={{display: { xs: 'none', md: 'block' }}} alt="Logo" />
                            <Box
                                component="img"
                                src={LogoMobile}
                                sx={{
                                        display: {
                                                xs: 'block',
                                                md: 'none' 
                                        },
                                        width: ({
                                            xs: '80%',
                                            sm: '50%',
                                        }),
                                        height:'auto',
                                    }}
                                alt="Logo"
                            />
                        </Grid>
                        <Grid item container xs={12}>
                            <Grid item xs={12}>
                                <Typography sx={{
                                    marginLeft: (theme) => theme.spacing(12),
                                    textAlign: 'left',
                                    marginTop: (theme) => theme.spacing(7),
                                    width: {
                                        xs:'40%',
                                        md:'50%',
                                        lg:'40%',
                                    },
                                    display: {
                                        xs: 'none',
                                        md: 'block',
                                    }
                                }}>
                                    Die Kanzlei berät Arbeitnehmer und Unternehmen in allen arbeitsrechtlichen Belangen
                                    und zu notariellen und anwaltlichen Fragen des (Grund-) Eigentums und
                                    des Vererbens. Darüber hinaus bieten wir auch eine außergerichtliche
                                    Streitschlichtung im Wege der Mediation an.
                                    <br /><br />
                                    Sie haben bereits ein konkretes Anliegen? Schreiben Sie uns!
                                </Typography>
                            </Grid>
                            <Grid item xs={0} md={6} lg={5}></Grid>
                            <Grid   item
                                    xs={12}
                                    md={6}
                                    lg={7}
                                    sx={{
                                        display:{
                                            xs: 'flex',
                                            md: 'flex'
                                        },
                                        justifyContent: {
                                            xs: 'space-around',
                                            md: 'flex-start',
                                            lg: 'flex-start',
                                        },
                                        paddingTop: theme => ({
                                            xs: theme.spacing(3),
                                            md: 0,
                                        })
                                }}>
                                <IconButton 
                                sx={{
                                    backgroundColor: theme.palette.red.main,
                                    '&:hover': {
                                        backgroundColor: theme.palette.blue.light,
                                    },
                                    padding: {
                                        xs: '20px',
                                        sm: '15px',
                                    },
                                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px',
                                }}
                                    href="#"
                                    onClick={onClickMessage}
                                >
                                    <Box
                                        component="img"
                                        src={MailIcon}
                                        sx={{
                                                width: {
                                                    xs: '50px',
                                                    sm: '30px',
                                                },
                                                height: {
                                                    xs: '50px',
                                                    sm: '30px',
                                                },
                                            }}
                                        />
                                </IconButton>

                                <IconButton 
                                sx={{
                                    backgroundColor: theme.palette.red.main,
                                    '&:hover': {
                                        backgroundColor: theme.palette.blue.light,
                                    },
                                    padding: {
                                        xs: '20px',
                                        sm: '15px',
                                    },
                                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px',
                                    marginLeft:(theme) => ({
                                        xs: theme.spacing(0),
                                        md: theme.spacing(9),
                                    }),
                                    marginTop:(theme) => ({
                                        xs: theme.spacing(0),
                                    }),
                                    transform: theme => ({
                                        xs: `translate(0px, ${theme.spacing(0)})`,
                                        lg: `translate(0px, ${theme.spacing(-12)})`,
                                    })
                                }}
                                    href="tel:+495331996980"
                                >
                                                                        <Box
                                        component="img"
                                        src={PhoneIcon}
                                        sx={{
                                                width: {
                                                    xs: '50px',
                                                    sm: '30px',
                                                },
                                                height: {
                                                    xs: '50px',
                                                    sm: '30px',
                                                },
                                            }}
                                        />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid id="about" item container xs={12} sx={{
                        minHeight: '500px',
                        position:'relative',
                        backgroundColor:(theme) => theme.palette.primary.light,
                    }}>
                        {getLawyerBio()}
                    <Grid item container xs={12}>
                        <Grid item sx={{
                        }}
                        >
                            <Box sx={{marginTop:(theme) => ({
                                xs: '-300px',
                                md: '-220px',
                            })
                            }} id="lawyers"/>
                        </Grid>
                        <Grid item xs={0} md={4} lg={6}>
                        </Grid>
                        <Grid  
                            item
                            container
                            xs={12}
                            md={8}
                            lg={6}
                            sx={{
                                justifyContent: ({
                                    xs: 'space-around',
                                    md: 'flex-start',
                                }),
                            }}
                        >
                            {Array.isArray(props.lawyers) && props.lawyers.map((e,i) => (
                                <Grid item key={i}>
                                    <LawyerPortrait gap={theme.spacing(i * 12)} onClick={() => {setLawyer(e);}} img={e.acf?.foto?.url} selected={props.lawyer === e} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                            <Typography
                                    sx={{
                                        paddingLeft:theme => ({
                                            xs: theme.spacing(3),
                                            md: theme.spacing(9),
                                        }),
                                        textTransform: 'uppercase',
                                        color: (theme) => theme.palette.blue.main,
                                        fontSize: theme => ({
                                            xs: '1.4rem',
                                            md: '2.125rem',
                                        }),
                                    }}
                                    textAlign="left"
                                    variant="h4"
                            >
                                Unsere Schwerpunkte
                            </Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Container
                            maxWidth="xl"
                            sx={{
                                marginTop:theme => ({
                                    xs: theme.spacing(0), 
                                    md: theme.spacing(12), 
                                }),
                                marginBottom: theme => ({
                                    xs: theme.spacing(0),
                                    md: theme.spacing(12),
                                }),
                            }}
                            >
                            <Stack sx={{width: '100%', flexWrap:'wrap'}} justifyContent="space-around" direction="row">
                                {props.offers?.map((offer, i) => (
                                    <Box
                                        key={i}
                                        sx={{ 
                                            marginBottom: theme => ({ xs: theme.spacing(6), md: 0 })
                                    }}
                                    >
                                        <InfoCard key={i} icon={offer.icon} body={offer.summary} buttonText={offer.title} onClick={() => onClickDetail(i)} />
                                    </Box>
                                ))}
                            </Stack>
                    </Container>
                    </Grid>

                    </Grid>
                </Grid>
        </Box>    
    )
}
import './App.css';
import MainPage from './components/MainPage';
import CookieBanner from './components/modules/CookieBanner';
import { useCookies } from 'react-cookie';
import WpApiPostContent from './components/modules/WpApiPostContent';
import React, { useEffect } from 'react';
import axios from 'axios';
import { WP_URL_COOKIES } from './components/const/settings';
import WpContactContent from './components/modules/WpContactContent';
import { Fade } from '@mui/material';

function App() {
  const [cookies, setCookie] = useCookies();

  const onCloseCookieBanner = (selectedCookies) => {
    setCookie("cookiePref", selectedCookies);
  }

  return (
      <div className="App">
          <CookieBanner open={cookies.cookiePref === undefined} cookies={cookies} onClose={onCloseCookieBanner} />
        <MainPage maxWidth="1920px" />
      </div>
  );
}

export default App;

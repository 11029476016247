import { Box, Collapse, Container, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ParallaxBanner } from 'react-scroll-parallax';
import {decode} from 'html-entities';

export default function Section(props) {
    
    const onClose = (e) => {
        props.onClose?.(e);
    }
    return (
        <Collapse in={props.expanded} sx={{position:'relative'}}>
            <Box id={props.id} sx={{
                position: 'absolute',
                top: theme => ({
                    xs: theme.spacing(-24),
                    md: theme.spacing(0),
                }),
            }}></Box>
            <ParallaxBanner
                layers={[{ image: props.backgroundImage, speed: -30, style: {zIndex:-20}}]}
                style={{
                    height:'100%',
                }}
            >
                <Stack spacing={3} sx={{
                    width: '100%',
                    // minHeight: '100vh',
                    // maxHeight: '100vh',
                    height: props.fixedHeight ? '90vh' : 'inherit',
                    backgroundColor: (theme) => (props.backgroundImage !== undefined) ? 'none' : (props.backgroundColor || theme.palette.primary.light),
                }}>
                    <Box sx={{textAlign: "right", height: '36px'}}>
                        <IconButton onClick={onClose} size="36px" sx={{display: props.closeable ? 'inline-flex' : 'none', fontSize: '36px', fontWeight: 'light', color: props.titleColor}} variant="text">
                            <CloseOutlinedIcon sx={{fontSize: '3rem'}}/>
                        </IconButton>
                    </Box>
                    <Typography
                        sx={{
                            paddingTop: theme => ({
                                xs: theme.spacing(3),
                                md: theme.spacing(6),
                            }), 
                            paddingLeft:theme => ({
                                xs: theme.spacing(6),
                                md: theme.spacing(9),
                            }),
                            textTransform: 'uppercase',
                            fontSize: theme => ({
                                xs: '1.4rem',
                                md: '2.125rem',
                            }),
                            color: (theme) => props.titleColor || theme.palette.primary.main
                        }}
                        textAlign="left"
                        variant="h4"
                    >
                        {decode(props.title)}
                    </Typography>
                    <Container
                        maxWidth="false"
                        sx={{
                            paddingLeft: theme => ({
                                xs: theme.spacing(6),
                                md: theme.spacing(12),
                            }),
                            paddingRight: theme => ({
                                xs: theme.spacing(0),
                                md: theme.spacing(8),
                            }),
                        }}
                        >
                        {props.children}
                    </Container>
                </Stack>
            </ParallaxBanner>
        </Collapse>
    )
}